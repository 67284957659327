import Vuex from 'vuex';
import Vue from 'vue';

import API from '../config/api.js'
import router from '../router/index.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
    },
    state: {
        isLoading: false,
        allJobs: [],
        allStations: [],
        currentUser: {},
        roomInformation: [],
        room: {},
        players: [],
        score: [],
        roomScoreInfo: [],
        globalConnectionInfo: {},
        defaultImage: "https://res.cloudinary.com/dflj1n3fn/image/upload/v1690274484/wold_r5bnpw.jpg",
        imageUrl: process.env.NODE_ENV == "production" ? process.env.VUE_APP_IMAGE_URL_LIVE : process.env.VUE_APP_IMAGE_URL ,

    },
    getters: {
        getJobs: (state) => state.allJobs,
        getStations: (state) => state.allStations,
        getCurrentUser: (state) => state.currentUser,
        isUserLoggedIn: (state) => {
            return Object.keys(state.currentUser).length == 0 ? false : true;
        },
        getRoom: (state) => state.room,
        getRoomScoreInfo: (state) => state.roomScoreInfo,
        getScore: (state) => state.score,
        getPlayers: (state) => state.players,
        getRoomInformation: (state) => state.roomInformation,
        getImageUrl: (state) => state.imageUrl,
        getDefaultImage: (state) => state.defaultImage,
        getUserImage: (state) => {
            return state.currentUser.profileImage == null ? state.defaultImage : state.imageUrl + state.currentUser.profileImage.path;
        },
        getGlobalConnectionInfo: (state) => state.globalConnectionInfo
    },
    actions: {       
        userLogin: async ({commit}, user) => {
            try {
                let response = await API.post('admin/login', user);
                commit('setCurrentUser', response.data.user);
                return true
            } catch (error) {
                return error.response
            }
        },
        verify: async ({commit}) =>  {
            try {
                let response = await API.get('admin/verify');
                commit('setCurrentUser', response.data.user)
                return true
            } catch (error) {
                return error.response
            }
        },
        updateUserProfile: async ({commit}, data) => {
            try {
                let response = await API.post('admin/update', data);
                console.log('res', response);
                commit('setCurrentUser', response.data.user);
                return true
            } catch (error) {
                return error.response                
            }
        },

        changePassword: async ({commit}, data) => {
            try {
                let response = await API.post('admin/change-password', data);
                return response;
            } catch (error) {
                return error.response                
            }
        },

        logout: async ({commit}) => {
            commit('logoutUser');
            router.push({name: 'login'})
        },

        createRoom: async ({commit}, data) => {
            try {
                let response = await API.post('room/create', data);
                return response
            } catch (error) {
                return error.response
            }
        },

        fetchJobs: async ({commit}) => {
            try {
                let response = await API.get('job/fetchAll')
                commit('setJobs', response.data.jobs)
            } catch (error) {
                return error.response;
            }
        },
        fetchStations: async ({commit}) => {
            try {
                let response = await API.get('station/fetchAll')
                commit('setStations', response.data.stations)
            } catch (error) {
                return error.response;
            }
        },

        checkIfRoomIsActive: async({commit}, roomId) => {
            try {
                let response = await API.get(`room/find/${roomId}`)
                return response
            } catch (error) {
                return error.response
            }
        },

        setPlayers: async ({commit}) => {
            try {
                let response = await API.get('player/fetchAll');
                commit('SET_PLAYERS', response.data.players)
                return response
            } catch (error) {
                return error.response
            }
        },

        setScore: async ({commit}, data) => {
            try {
                let response = await API.post('admin/room/score', data);
                commit('SET_SCORE', response.data.score)
                return response;
            } catch (error) {
                return error.response
            }
        },

        setRoomInformation: async ({commit}, roomId) => {
            try {
                let response = await API.get(`room-information/${roomId}`);
                commit('SET_ROOM_INITIAL_INFO', response.data.roomInitialInfo)
                commit('SET_ROOM', response.data.roomInfo)
                return response
            } catch (error) {
                return error.response
            }
        },


        editStation: async ({commit}, data) => {
            try {
                let response = await API.post('station/update', data);
                return response;
            } catch (error) {
                console.log('err', error.response);
                return error.response              
            }
        },

        setRoomScoreInfo: async ({commit}) => {
            try {
                let response = await API.get(`admin/room`);
                commit('SET_ROOM_SCORE_INFO', response.data.room);
                return response;
            } catch (error) {
                return error.response                
            }
        },

        setGlobalConnectionInfo: async ({commit}, data) => {
            commit('SET_GLOBAL_CONNECTION_INFO', data);
        }
    },
    mutations: {
        setJobs: (state, jobs) => state.allJobs = jobs,
        setStations: (state, jobs) => state.allStations = jobs,
        setCurrentUser: (state, user) => {
            state.currentUser = user,
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', user.apiToken);
        },
        logoutUser: (state) => {
            state.currentUser = {},
            localStorage.removeItem('user');
            localStorage.removeItem('token');
        },
        SET_ROOM_INITIAL_INFO: (state, roomInitialInfo) => state.roomInformation = roomInitialInfo,
        SET_ROOM: (state, room) => state.room = room,
        SET_PLAYERS: (state, players) => state.players = players,
        SET_SCORE: (state, score) => state.score = score,
        SET_ROOM_SCORE_INFO: (state, roomScoreInfo) => state.roomScoreInfo = roomScoreInfo,
        SET_GLOBAL_CONNECTION_INFO: (state, data) => state.globalConnectionInfo = data
        
    }

});