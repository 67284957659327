import io from "socket.io-client";

const socket = io(
  process.env.NODE_ENV == "production"
    ? process.env.VUE_APP_SOCKET_URL_LIVE
    : process.env.VUE_APP_SOCKET_URL,
  {
    cert: process.env.NODE_ENV == "production" ? process.env.SSL_CERT : "",
    key: process.env.NODE_ENV == "production" ? process.env.SSL_KEY : "",
  }
  // {
  // path: process.env.NODE_ENV != 'production' ? '/skillsforlife-server/socket.io' : '/socket.io',
  // transports: ['websocket'],
  // withCredentials: true,
  // extraHeaders: {
  //     "allow": true
  // }
  // },
);

export default socket;
