import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import store from './store'
import { globalMixins } from './mixin/mixin'
import VueCryptojs from 'vue-cryptojs'
import socket from './config/socket'


// primevue
import PrimeVue from 'primevue/config';

// theme and css
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// primevue components
import ToastService from 'primevue/toastservice';
import Password from 'primevue/password';

Vue.use(PrimeVue);
Vue.use(ToastService);
Vue.mixin(globalMixins);
Vue.use(VueCryptojs)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(require('vue-moment'));


Vue.config.productionTip = false
Vue.component('PrimePassword', Password)
Vue.prototype.$socket = socket;


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
