<template>
  <div id="app">
    <loading :active="$store.state.isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <router-view></router-view>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'app',
  components: {
    Loading
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getGlobalConnectionInfo'])
  },
  methods: {
    ...mapActions(['verify', 'logout', 'setGlobalConnectionInfo', 'checkIfRoomIsActive'])
  },
  async mounted() {

    let response = await this.verify();
    response == true ? null : await this.logout()

    let roomId = localStorage.getItem("roomId");

    if (roomId != null) {
      let response = await this.checkIfRoomIsActive(roomId)

      if (response.status != 400) {
        this.$socket.emit("joinRoom", {
          playerId: this.getCurrentUser.id,
          roomId: roomId,
          isAdmin: true,
        });

        this.$socket.on("welcomeAdminRoom", async (arg) => {
          await this.setGlobalConnectionInfo(arg);
          console.log('globalcon', this.getGlobalConnectionInfo);

        });

      } else {
        this.$router.push({ name: "dashboard" })
      }

    }
  }
}
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "./assets/scss/style";
</style>
