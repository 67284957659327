import Vue from 'vue'
import Router from 'vue-router'
import login from '../pages/application-pages/login.vue'
import store from '../store'
import layout from '../layout'
import { authGuard } from '../config/guards'



Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '/',
  routes: [
    {
      path: "/",
      beforeEnter: authGuard,
      name: "base"
    },
    {
      path: "/login",
      component: login,
      name: "login"
    },
    {
      path: "/dashboard",
      component: layout,
      children: [
        {
          path: "",
          name: "dashboard",
          component: () => import("@/pages/dashboard"),
        },
      ],
    },
    {
      path: "/room",
      component: layout,
      children: [
        {
          path: "create-room",
          name: "create-room",
          component: () => import("@/pages/application-pages/create-room"),
        },
        {
          path: "game-room/:id",
          name: "game-room",
          component: () => import("@/pages/application-pages/room"),
        },
      ],
    },
    {
      path: "/player",
      component: layout,
      children: [
        {
          path: "all-players",
          name: "all-players",
          component: () => import("@/pages/application-pages/all-players"),
        },
      ],
    },
    {
      path: "/profile",
      component: layout,
      children: [
        {
          path: "/",
          name: "my-profile",
          component: () => import("@/pages/application-pages/my-profile"),
        },
      ],
    },
    {
      path: "/room",
      component: layout,
      children: [
        {
          path: "score/:roomId?",
          name: "room-score",
          component: () => import("@/pages/application-pages/score"),
        },
      ],
    },
    {
      path: "/room",
      component: layout,
      children: [
        {
          path: "info",
          name: "room-info",
          component: () => import("@/pages/application-pages/room-info"),
        },
      ],
    },
    // {
    //   path: "/job",
    //   component: layout,
    //   children: [
    //     {
    //       path: "all-jobs",
    //       name: "all-jobs",
    //       component: () => import("@/pages/application-pages/all-jobs"),
    //     },
    //   ],
    // },
    // {
    //   path: "/station",
    //   component: layout,
    //   children: [
    //     {
    //       path: "all-stations",
    //       name: "all-stations",
    //       component: () => import("@/pages/application-pages/all-stations"),
    //     },
    //   ],
    // },
    {
      path: "*",
      redirect: "/error-404",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "error-404",
          component: () => import("@/pages/samples/error-pages/error-404"),
        },
      ],
    },
  ],
})

import socket from '../config/socket';

// router.afterEach((to, from) => {

//   // if (to.name != 'game-room') {

//   //   let roomId = localStorage.getItem("roomId");

//   //   if (roomId != null) {
//   //     socket.emit("joinRoom", {
//   //       playerId: store.getters.getCurrentUser.id,
//   //       roomId: roomId,
//   //       isAdmin: true,
//   //     });

//   //     socket.on("welcomeAdminRoom", async (arg) => {
//   //       await store.dispatch('setGlobalConnectionInfo', arg)
//   //       console.log('globalcon', store.getters.getGlobalConnectionInfo);
//   //       console.log("welcomeAdminRoom", arg);

//   //     });

//   //   }

//   // }
// });

export default router;