export const globalMixins = {
    methods: {
        check(){return "hello"},
        showAuthenticationMessages(response) {
            let messages = [];

            let { status } = response;
            let type = null;

            status == 200 ? type = 'success' : type = 'error';

            let hasErrors = null;
            response.data.hasOwnProperty('errors') ? hasErrors = true : hasErrors = false;
            
            switch (hasErrors) {
                case false:
                    messages.push(
                        this.$toast.add({
                            severity: type,
                            summary: response.data.message,
                            detail: type,
                            life: 3000,
                        })
                    );
                    return messages;

                case true:
                    Object.keys(response.data.errors).forEach((key) => {
                        messages.push(
                            this.$toast.add({
                                severity: type,
                                summary: response.data.errors[key][0],
                                detail: key,
                                life: 3000,
                            })
                        );
                    });
                    return messages;
            }
        },
        showSuccessMessage(message){
            this.$toast.add({
                severity: 'success',
                summary: message,
                detail: 'success',
                life: 3000,
            })
        },
        showErrorMessage(message){
            this.$toast.add({
                severity: 'error',
                summary: message,
                detail: 'error',
                life: 3000,
            })
        },
        encryptNonObj(text){
            return this.$CryptoJS.AES.encrypt(text, process.env.VUE_APP_SECRET_KEY).toString()
        },
        decryptNonObj(encryptedText){
            return this.$CryptoJS.AES.decrypt(encryptedText, process.env.VUE_APP_SECRET_KEY).toString(this.$CryptoJS.enc.Utf8)
        },
        encryptObj(obj){
            return this.$CryptoJS.AES.encrypt(JSON.stringify(obj), process.env.VUE_APP_SECRET_KEY).toString()
        },
        decryptObj(encryptedObj){
            return JSON.parse(this.$CryptoJS.AES.decrypt(encryptedObj, process.env.VUE_APP_SECRET_KEY).toString(this.$CryptoJS.enc.Utf8))
        },
        generateExcerpt(description, maxLength) {
            // const maxLength = 40; // Set your desired maximum length
            return description.length > maxLength
              ? description.substring(0, maxLength) + "..."
              : description;
          },
    },
};

export const setTableItems = {
    methods: {
        
    }
}