<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-profile">
          <a href="javascript:void(0);" class="nav-link">
            <div class="nav-profile-image">
              <img :src="this.getUserImage" alt="profile">
              <span class="login-status online"></span>
            </div>
            <div class="nav-profile-text d-flex flex-column">
              <span class="font-weight-bold mb-2">{{ getCurrentUser.userName }}</span>
              <!-- <span class="text-secondary text-small">Project Manager</span> -->
            </div>
            <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </a>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/">
            <span class="menu-title">Dashboard</span>
            <i class="mdi mdi-home menu-icon"></i>
          </router-link>
        </li>
         <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/room/create-room">
            <span class="menu-title">Room</span>
            <i class="fa fa-gamepad menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/player/all-players">
            <span class="menu-title">Players</span>
            <i class="fa fa-users menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/room/info">
            <span class="menu-title">Room History</span>
          <i class="fa fa-gamepad menu-icon"></i>
          </router-link>
        </li>
        <!-- <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/job/all-jobs">
            <span class="menu-title">Jobs</span>
            <i class="mdi mdi-forum menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/station/all-stations">
            <span class="menu-title">Stations</span>
            <i class="mdi mdi-forum menu-icon"></i>
          </router-link>
        </li> -->
      </ul>
    </nav>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'sidebar',
 data () {
    return {
      collapses: [ 
     { show: false },
     { show: false },
     { show: false }
    ]
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getUserImage'])
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    }
  },
  mounted () {
    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },
  watch:{
    $route () {
       document.querySelector('#sidebar').classList.toggle('active');
    }
  } 
}
</script>